import { Plugin } from 'vue'

import { handleModuleLoadError, isModuleLoadError } from '../utils'

export const errorHandlerPlugin: Plugin = (app) => {
  if (process.env.COMMAND !== 'serve') {
    app.config.errorHandler = (error, instance, info) => {
      if (isModuleLoadError(error)) {
        handleModuleLoadError()
      }

      if (process.env.NODE_ENV === 'development') {
        console.error(info, error, instance)
      }
      else {
        console.error(error)
      }
    }

    const router = app.config.globalProperties.$router

    if (router) {
      const removeErrorHandler = router.onError((error: unknown) => {
        if (isModuleLoadError(error)) {
          handleModuleLoadError()
        }
      })

      // There's no api to add onMounted callbacks via plugins ¯\_(ツ)_/¯
      // https://github.com/vuejs/router/blob/main/packages/router/src/router.ts#L1295C13-L1295C23
      const unmountApp = app.unmount
      app.unmount = () => {
        removeErrorHandler()
        unmountApp()
      }
    }
  }
}
